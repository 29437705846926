<template>
	<v-card
		:elevation="borderless ? 0 : null"
		:outlined="!borderless"
		:max-width="maxWidth"
		:width="width"
		v-bind="$attrs"
	>
		<template v-if="header || (header == null && (icon || titulo))">
			<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4">
				<v-card-subtitle class="pa-0 d-flex align-center w-100">
					<v-icon left small v-text="'mdi-' + icon" />
					<slot name="titulo">{{ titulo }}</slot>
				</v-card-subtitle>
			</v-sheet>
			<v-divider />
		</template>

		<template v-for="(dato, i) in datos">
			<!-- <v-row class="pa-4 d-inline-flex w-100" :key="i">
				<v-col cols="12" class="col-md-4 font-weight-bold">
					<span v-text="dato.nombre || '\xa0'" />
				</v-col>
				<v-col cols="12" class="col-md-8"><span v-text="dato.valor" /></v-col>
			</v-row> -->
			<AuxInput :title="dato.nombre" :value="dato.valor" :key="i" />
			<v-divider v-if="i + 1 < datos.length" :key="i + Math.random()" />
		</template>
		<slot></slot>
	</v-card>
</template>

<script>
export default {
	components: {
		AuxInput: () => import('@/components/AuxInput.vue'),
	},
	props: {
		header: { type: Boolean, default: null },
		icon: String,
		titulo: String,
		datos: { type: Array, default: () => [] },
		maxWidth: String | Number,
		width: String | Number,
		borderless: Boolean,
	},
};
</script>

<style>
span {
	font-size: 0.75rem;
}
.w-100 {
	width: 100%;
}
</style>